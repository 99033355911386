import App from '@/vue/App.vue';
import { createApp } from 'vue';
import accordion from "../../templates/_components/accordion/accordion";
import imageGallery from "../../templates/_components/gallery/images";

// App main
const main = async () => {
    // Async load the Vue 3 APIs we need from the Vue ESM

    try {
        // Import Vendors
        const { default: vendors } = await import('./_vendors');
        await vendors();

        // Import Scripts
        const { default: scripts } = await import('./_scripts');
        await scripts();

        // Import Components
        const { default: components } = await import('./_components');
        await components();

        // Import Components
        const { default: vueComponents } = await import('./_vue-components');
        await vueComponents();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

// Execute async function
main().then( (root) => {
    accordion.init()
   // imageGallery.init()
    console.log('App Initialized')
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
